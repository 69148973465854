import React, { useEffect } from "react";
import "../App.css";
import "animate.css/animate.compat.css"; // Import Animate.css styles

function Donate() {
  useEffect(() => {
    // Function to initialize PayPal buttons
    const initializePayPal = () => {
      if (window.paypal && window.paypal.HostedButtons) {
        window.paypal
          .HostedButtons({
            hostedButtonId: "F5CX5BV5MYLTY",
          })
          .render("#paypal-container-F5CX5BV5MYLTY");
      } else {
        console.error("PayPal HostedButtons is not available.");
      }
    };

    // Load the PayPal script dynamically
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=BAANR2LWQQ09OedXC3bruUUexOXXeLldslEqiI_ey9VaOWJZze5QRgiPcVS_l5XtyPeqgPQQ_0g6_otLzI&components=hosted-buttons&enable-funding=venmo&currency=USD";
    script.async = true;
    script.onload = initializePayPal;
    script.onerror = () => {
      console.error("Failed to load PayPal SDK script.");
    };
    document.body.appendChild(script);

    // Cleanup script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="donatesection p-md-5 p-3">
      <div className="donateheading pt-5">Support WEWAD's Mission</div>
      <div className="donatepara pt-2 pb-4">
        Every donation helps us continue our mission to empower women and
        individuals with disabilities. With your support, we can provide
        resources, mentorship, and opportunities to those who need them most.
      </div>
      <div className="donatesubheading pb-4">Please send your donations to:</div>
      <div className="donategrid">
        <div className="donatesubgrid">
          <div className="donatesubgridheading pb-2 ps-2">
            Zenith Bank Account
          </div>
          <div className="donatesubgridpara p-3">
            Naira - 1312710308 <br /> <br />
            USD - 5074766685 <br /> <br />
            GBP - 5061455031
          </div>
        </div>
        <div className="donatesubgrid">
          <div className="donatesubgridheading pb-2 ps-">
            Globus Bank Account
          </div>
          <div className="donatesubgridpara p-3">
            Naira - 1000310757 <br /> <br />
            USD - 5000083615 <br /> <br />
            GBP - 6000012977
          </div>
        </div>
      </div>
      <div className="donategrid pt-4">
        <div className="donatesubgrid">
          <div className="donatesubgridheading pb-2 ps-2">
            American Bank Account
          </div>
          <div className="donatesubgridpara p-3">
            Account #: 875106156644 <br /> <br />
            ABA #: 125109019
          </div>
        </div>
        <div className="donatesubgrid">
          <div className="donatesubgridheading pb-2 ps-">
            PayPal Donation
          </div>
          <div id="paypal-container-F5CX5BV5MYLTY" className="p-3">
            {/* PayPal Hosted Buttons will be rendered here */}
          </div>
        </div>
      </div>
      <div className="donatefooterheading pt-4">
        Women Empowering Women and Disabled Foundation <br />
        (WEWAD FOUNDATION)
      </div>
      <div className="donatefootersubheading py-3">
        No Amount Is Too Small To Help.
        <br /> God Bless You All.
      </div>
    </div>
  );
}

export default Donate;
